import { IonButton, IonCard, IonModal } from "@ionic/react";
import { IonButtons, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import IndicatorContext, { Indicator } from "../data/indicator-context";
import { useForm } from "react-hook-form";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import "./Test.css";
import RecommendationContext, { Recommendation } from "../data/recommendation-context";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const Test: React.FC = () => {
  var cK1 = 0;
  var cK2 = 0;
  var cK3 = 0;
  var cK4 = 0;
  var cK5 = 0;
  var cK6 = 0;
  var cK7 = 0;
  var cK8 = 0;

  const indicatorCtx = useContext(IndicatorContext);
  const recommendationCtx = useContext(RecommendationContext);
  const [result1, setResult1] = useState<String>();
  const [result2, setResult2] = useState<String>();
  const [result3, setResult3] = useState<String>();
  const [result4, setResult4] = useState<String>();
  const [result5, setResult5] = useState<String>();
  const [result6, setResult6] = useState<String>();
  const [result7, setResult7] = useState<String>();
  const [result8, setResult8] = useState<String>();
  const [hasil, setHasil] = useState<String>();
  const [listIndicators, setListIndicators] = useState<Indicator[]>();
  const [listRecommendation, setListRecommendation] = useState<Recommendation[]>();

  // Memuat Context
  useEffect(() => {
    setListIndicators(indicatorCtx.indicators);
  }, [indicatorCtx.indicators]);
  useEffect(() => {
    setListRecommendation(recommendationCtx.recommendations);
  }, [recommendationCtx.recommendations]);

  // Event saat Submit data form
  const { handleSubmit, control, reset } = useForm();
  const submitt = (data: any) => {
    var j;
    var result1, result2, result3, result4, result5, result6, result7, result8;
    for (j = 0; j < indicatorCtx.indicators.length; j++) {
      if (indicatorCtx.indicators[j].type === "K1") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK1++;
        }
      } else if (indicatorCtx.indicators[j].type === "K2") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK2++;
        }
      } else if (indicatorCtx.indicators[j].type === "K3") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK3++;
        }
      } else if (indicatorCtx.indicators[j].type === "K4") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK4++;
        }
      } else if (indicatorCtx.indicators[j].type === "K5") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK5++;
        }
      } else if (indicatorCtx.indicators[j].type === "K6") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK6++;
        }
      } else if (indicatorCtx.indicators[j].type === "K7") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK7++;
        }
      } else if (indicatorCtx.indicators[j].type === "K8") {
        if (indicatorCtx.indicators[j].value === 1) {
          cK8++;
        }
      }
    }

    //Perhitungan Persentase
    result1 = ((cK1 / 11) * 100).toFixed(2);
    result2 = ((cK2 / 11) * 100).toFixed(2);
    result3 = ((cK3 / 11) * 100).toFixed(2);
    result4 = ((cK4 / 11) * 100).toFixed(2);
    result5 = ((cK5 / 11) * 100).toFixed(2);
    result6 = ((cK6 / 11) * 100).toFixed(2);
    result7 = ((cK7 / 11) * 100).toFixed(2);
    result8 = ((cK8 / 11) * 100).toFixed(2);

    setResult1(result1);
    setResult2(result2);
    setResult3(result3);
    setResult4(result4);
    setResult5(result5);
    setResult6(result6);
    setResult7(result7);
    setResult8(result8);
  };

  // Chart Configurations
  const dataSource = {
    data: [
      { label: "Verbal", value: result1 },
      { label: "Logis", value: result2 },
      { label: "Visual", value: result3 },
      { label: "Kinestatik", value: result4 },
      { label: "Musikal", value: result5 },
      { label: "Interpersonal", value: result6 },
      { label: "Intrapersonal", value: result7 },
      { label: "Naturalis", value: result8 },
    ],
    chart: {
      caption: "Hasil Tes Intelligence",
      subCaption: "",
      xAxisName: "Tipe Kecerdasan",
      yAxisName: "Tingkat Kecerdasan",
      numberSuffix: "%",
    },
  };
  const chartConfigs = {
    type: "column2d",
    width: 515,
    height: 250,
    dataFormat: "json",
    dataSource: dataSource,
  };

  var max = Math.max(Number(result1), Number(result2), Number(result3), Number(result4), Number(result5), Number(result6), Number(result7), Number(result8));

  const hitung = () => {
    if (max === Number(result1)) {
      setHasil("Dominan: Kecerdasan Verbal/Linguistik");
    } else if (max === Number(result2)) {
      setHasil("Dominan: Kecerdasan Logis");
    } else if (max === Number(result3)) {
      setHasil("Dominan: Kecerdasan Visual");
    } else if (max === Number(result4)) {
      setHasil("Dominan: Kecerdasan Kinestatik");
    } else if (max === Number(result5)) {
      setHasil("Dominan: Kecerdasan Musikal");
    } else if (max === Number(result6)) {
      setHasil("Dominan: Kecerdasan Interpersonal");
    } else if (max === Number(result7)) {
      setHasil("Dominan: Kecerdasan Intrapersonal");
    } else if (max === Number(result8)) {
      setHasil("Dominan: Kecerdasan Naturalis");
    }
  };

  // End of the Chart Configurations

  const setValue = (value: 0 | 1, i: Indicator) => {
    indicatorCtx.setValue(i, value);
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start" color="white">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Intelligence Test</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="background ion-padding content" fullscreen>
        <IonModal className="modal" isOpen={showModal}>
          <IonItem style={{ marginTop: "40px", marginBottom: "20px", marginLeft: "auto", marginRight: "auto" }}>
            <IonRow className="chart">
              <ReactFC {...chartConfigs} />
            </IonRow>
          </IonItem>

          <IonLabel style={{ marginBottom: "7px", marginLeft: "15px" }}>{hasil}</IonLabel>
          <IonContent className="kmodal">
            {listRecommendation?.map((i) => {
              if (max === Number(result1)) {
                if (i.type === "R1") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result2)) {
                if (i.type === "R2") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result3)) {
                if (i.type === "R3") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result4)) {
                if (i.type === "R4") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result5)) {
                if (i.type === "R5") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result6)) {
                if (i.type === "R6") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result7)) {
                if (i.type === "R7") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              } else if (max === Number(result8)) {
                if (i.type === "R8") {
                  return (
                    <IonList className="listmodal" key={i.id}>
                      <IonLabel>{i.job}</IonLabel>
                    </IonList>
                  );
                }
              }
            })}
          </IonContent>
          <IonButton className="closebtn" onClick={() => setShowModal(false)}>
            Close
          </IonButton>
        </IonModal>
        <form className="glassmorphism ion-padding ion-padding-horizontal ion-margin-horizontal" onSubmit={handleSubmit(submitt)}>
          {listIndicators?.map((i, idx) => {
            if (idx % 11 !== 0) {
              return (
                <IonList key={i.id} class="bg-trans">
                  <IonGrid className="ion-margin-horizontal">
                    <IonRow>
                      <IonLabel color="primary" className="ion-text-start ion-text-lg-wrap">
                        {idx + 1}. <b>{i.question}</b>
                      </IonLabel>
                    </IonRow>
                    <IonRow>
                      <IonRadioGroup onIonChange={(e) => setValue(e.detail.value, i)} key={i.type} id={i.id} className="ion-no-padding">
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel class="yes" className="yes">
                            Ya
                          </IonLabel>
                          <IonRadio slot="start" value={1} color="success"></IonRadio>
                        </IonItem>
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel class="no" className="no">
                            Tidak
                          </IonLabel>
                          <IonRadio slot="start" value={0} color="danger"></IonRadio>
                        </IonItem>
                      </IonRadioGroup>
                    </IonRow>
                  </IonGrid>
                </IonList>
              );
            } else {
              var type = "";
              if (i.type === "K1") {
                type = "Kecerdasan Verbal/Lingustik";
              } else if (i.type === "K2") {
                type = "Kecerdasan Logis";
              } else if (i.type === "K3") {
                type = "Kecerdasan Visual";
              } else if (i.type === "K4") {
                type = "Kecerdasan Kinestatik";
              } else if (i.type === "K5") {
                type = "Kecerdasan Musikal";
              } else if (i.type === "K6") {
                type = "Kecerdasan Interpersonal";
              } else if (i.type === "K7") {
                type = "Kecerdasan Intrapersonal";
              } else if (i.type === "K8") {
                type = "Kecerdasan Naturalis";
              }
              return (
                <IonList key={i.id} class="bg-trans">
                  <IonCard color="trans" class="toolbar-title" className="ion-margin-bottom">
                    <IonTitle color="secondary" className="ion-text-lg-wrap ion-margin-vertical ion-text-center">
                      <h1 className="ion-margin-vertical ion-text-wrap">
                        <b>{type}</b>
                      </h1>
                    </IonTitle>
                  </IonCard>
                  <IonGrid className="ion-margin-horizontal">
                    <IonRow>
                      <IonLabel color="primary" className="ion-text-start ion-text-lg-wrap">
                        {idx + 1}. <b>{i.question}</b>
                      </IonLabel>
                    </IonRow>
                    <IonRow>
                      <IonRadioGroup onIonChange={(e) => setValue(e.detail.value, i)} key={i.type} id={i.id} className="ion-no-padding">
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel class="yes" className="yes">
                            Ya
                          </IonLabel>
                          <IonRadio slot="start" value={1} color="success"></IonRadio>
                        </IonItem>
                        <IonItem color="trans" className="ion-text-start" class="bg-trans">
                          <IonLabel class="no" className="no">
                            Tidak
                          </IonLabel>
                          <IonRadio slot="start" value={0} color="danger"></IonRadio>
                        </IonItem>
                      </IonRadioGroup>
                    </IonRow>
                  </IonGrid>
                </IonList>
              );
            }
          })}

          <IonButton
            onClick={() => {
              setShowModal(true);
              hitung();
            }}
            className="ion-margin-top"
            type="submit"
            expand="block"
          >
            Submit
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Test;
// function useForm(): { control: any; handleSubmit: any } {
//   throw new Error("Function not implemented.");
// }
