import React from "react";

export interface Recommendation {
  type: string;
  id: string;
  job: string;
}

export interface Context {
  recommendations: Recommendation[];
  addRecommendation: (type: string, id: string, job: string) => void;
}

const RecommendationContext = React.createContext<Context>({
  recommendations: [],
  addRecommendation: () => {},
});

export default RecommendationContext;
