import React, { useState } from "react";
import IndicatorContext, { Indicator } from "./indicator-context";

type contextProps = {
  children: React.ReactNode;
};
const IndicatorContextProvider = (props: contextProps) => {
  const [indicators, setIndicators] = useState<Indicator[]>([
    { type: "K1", id: "I1", question: "Suka Bercerita", value: 0 },
    { type: "K1", id: "I2", question: "Memiliki ingatan bagus tentang hal kecil", value: 0 },
    { type: "K1", id: "I3", question: "Suka dengan permainan kata-kata, seperti TTS dan Puzzle", value: 0 },
    { type: "K1", id: "I4", question: "Hobi membaca buku", value: 0 },
    { type: "K1", id: "I5", question: "Mampu berbicara didepan umum, termasuk menyampaikan apa pun yang ada dirasakan", value: 0 },
    { type: "K1", id: "I6", question: "Menikmati argumen dan diskusi serta sering menyindir ketika berargumen", value: 0 },
    { type: "K1", id: "I7", question: "Sering menyampaikan dan menuliskan ide-ide yang ada di otak ", value: 0 },
    { type: "K1", id: "I8", question: "Mengingat dengan cara menyanyikan ", value: 0 },
    { type: "K1", id: "I9", question: "Bisa memperbaiki sesuatu setelah membaca buku panduannya ", value: 0 },
    { type: "K1", id: "I10", question: "Lebih suka menulis dan mencari informasi di perpustakaan", value: 0 },
    { type: "K1", id: "I11", question: "Saya merasa mudah untuk berbicara dengan orang-orang yang baru saya kenal", value: 0 },

    { type: "K2", id: "I12", question: "Menyukai permainan yang menggunakan logika", value: 0 },
    { type: "K2", id: "I13", question: "Saya sering mempunyai ide-ide yang unik ketika bermain dengan teman.", value: 0 },
    { type: "K2", id: "I14", question: "Mengurutkan sesuatu agar mudah diingat ", value: 0 },
    { type: "K2", id: "I15", question: "Penasaran dengan cara kerja suatu benda", value: 0 },
    { type: "K2", id: "I16", question: "Menyukai komuputer dan permainan tentang angka", value: 0 },
    { type: "K2", id: "I17", question: "Salah satu permainan favorit adalah monopoli atau catur", value: 0 },
    { type: "K2", id: "I18", question: "Mencari solusi dengan adil dan logis ", value: 0 },
    { type: "K2", id: "I19", question: "Memperbaiki barang yang rusak dengan melihat komponen ada", value: 0 },
    { type: "K2", id: "I20", question: "Lebih suka membuat grafik atau diagram ", value: 0 },
    { type: "K2", id: "I21", question: "Sangat menyukai matematika dan berhasil menyelesaikan soal hitungan adalah hal yang menyenangkan", value: 0 },
    { type: "K2", id: "I22", question: "Saya menikmati teka-teki silang, pencarian kata atau teka-teki kata lain", value: 0 },

    { type: "K3", id: "I23", question: "Hobi fotografi", value: 0 },
    { type: "K3", id: "I24", question: "Senang menggambar dan menciptakan sesuatu", value: 0 },
    { type: "K3", id: "I25", question: "Senang mengingat melalui diagram", value: 0 },
    { type: "K3", id: "I26", question: "Hobi mencorat-coret diatas kertas", value: 0 },
    { type: "K3", id: "I27", question: "Lebih suka memperhatikan gambar dari pada tulisan", value: 0 },
    { type: "K3", id: "I28", question: "Saya suka melihat pameran seperti pameran foto, mobil atau motor, atau produk-produk lainnya", value: 0 },
    { type: "K3", id: "I29", question: "Memperbaiki barang dengan cara melihat diagram mengenai cara kerja barang tersebut ", value: 0 },
    { type: "K3", id: "I30", question: "Lebih suka menggambarkan suatu hal yang menurut kamu penting", value: 0 },
    { type: "K3", id: "I31", question: "Suka melihat peta dari pada petunjuk tertulis mengenai letak suatu tempat ", value: 0 },
    { type: "K3", id: "I32", question: "Sering melamun", value: 0 },
    { type: "K3", id: "I33", question: "Saya dapat membayangkan gambar dengan jelas ketika saya menutup mata saya", value: 0 },

    { type: "K4", id: "I34", question: "Tidak suka duduk terdiam untuk waktu yang lama ", value: 0 },
    { type: "K4", id: "I35", question: "Sering menggerakan anggota badan ketika berbicara dengan orang lain", value: 0 },
    { type: "K4", id: "I36", question: "Mengingat sesuatu dengan menuliskannya berkali-kali sampai memahaminya", value: 0 },
    { type: "K4", id: "I37", question: "Sering mengetuk-ngetukkan pensil ketika sedang belajar atau rapat", value: 0 },
    { type: "K4", id: "I38", question: "Saat berargumen, lebih memilih menyerang langsung atau menghindari lawan bicara ", value: 0 },
    { type: "K4", id: "I39", question: "Dalam memperbaiki barang, kamu membongkarnya terlebih dahulu baru mempebaikinya", value: 0 },
    { type: "K4", id: "I40", question: "Lebih memilih memindahkan suatu barang atau menciptakan suatu barang", value: 0 },
    { type: "K4", id: "I41", question: "Menyukai olah raga dan senam", value: 0 },
    { type: "K4", id: "I42", question: "Menyukai perkerjaan yang membentuk sesuatu", value: 0 },
    { type: "K4", id: "I43", question: "Menyentuh benda-benda yang baru kamu lihat", value: 0 },
    { type: "K4", id: "I44", question: "Lebih suka bergerak, petualangan, dan aktivitas luar rumah", value: 0 },

    { type: "K5", id: "I45", question: "Bisa dan senang belajar atau bekerja sambil mendengarkan musik", value: 0 },
    { type: "K5", id: "I46", question: "Dalam menginat sesuatu lebih mudah dengan membentuk sebuah irama", value: 0 },
    { type: "K5", id: "I47", question: "Ketika berargumen sering membuat bunyi-bunyian seperti menggebrak meja dan lain-lain ", value: 0 },
    { type: "K5", id: "I48", question: "Bisa menghafal nada-nada dari beberapa lagu yang kamu kenal", value: 0 },
    { type: "K5", id: "I49", question: "Ketika memperbaiki sesuatu, sering mengetuk-ngetukkan jari diatas barang yang rusak sambil mencari solusi", value: 0 },
    { type: "K5", id: "I50", question: "Suka mengubah dan memakai kata-kata baru pada nada atau musik yang dikenal", value: 0 },
    { type: "K5", id: "I51", question: "Memiliki minat yang besar dalam dunia musik dan mata pelajaran favorit saya adalah Seni Musik", value: 0 },
    { type: "K5", id: "I52", question: "Suka menyenandungkan lagu kesukaan ketika belajar atau bekerja", value: 0 },
    { type: "K5", id: "I53", question: "Suka bernyanyi", value: 0 },
    { type: "K5", id: "I54", question: "Bisa memainkan salah satu alat musik", value: 0 },
    { type: "K5", id: "I55", question: "Saya selalu bermimpi menjadi seorang musisi atau penyanyi", value: 0 },

    { type: "K6", id: "I56", question: "Saya suka menjadi pemimpin dan memiliki figure pemimpin", value: 0 },
    { type: "K6", id: "I57", question: "Dalam mengingat sesuatu, menggunakan orang lain untuk menguji ingatannya", value: 0 },
    { type: "K6", id: "I58", question: "Suka meminta bantuan teman atau ahli dalam berargumen", value: 0 },
    { type: "K6", id: "I59", question: "Jika barang kamu rusak, meminta orang yang ahli untuk memperbaikinya", value: 0 },
    { type: "K6", id: "I60", question: "Kamu lebih senang mengatur pembagian tugas dalam sebuah tim", value: 0 },
    { type: "K6", id: "I61", question: "Saya senang berkenalan dengan orang baru dan bisa bergaul dengan baik", value: 0 },
    { type: "K6", id: "I62", question: "Senang dengan aktifitas sosial", value: 0 },
    { type: "K6", id: "I63", question: "Memiliki banyak teman dekat", value: 0 },
    { type: "K6", id: "I64", question: "Senang mengajar orang lain", value: 0 },
    { type: "K6", id: "I65", question: "Senang bekerja atau belajar dalam sebuah tim/kelompok", value: 0 },
    { type: "K6", id: "I66", question: "Teman-teman sering meminta saran dari saya.", value: 0 },

    { type: "K7", id: "I67", question: "Dalam mengingat sesuatu, sering menutup mata dan membayangkan apa yang ingin diingat", value: 0 },
    { type: "K7", id: "I68", question: "Sering meminta izin untuk menenangkan diri atau menyendiri saat berargumen", value: 0 },
    { type: "K7", id: "I69", question: "Akan mempertimbangkan barang yag rusak, apakah layak di perbaiki atau tidak", value: 0 },
    { type: "K7", id: "I70", question: "Senang jika dapat memberikan apa yang dimiliki", value: 0 },
    { type: "K7", id: "I71", question: "Lebih memilih bekerja sendiri dan tidak ada gangguan orang lain", value: 0 },
    { type: "K7", id: "I72", question: "Sering menulis buku diari", value: 0 },
    { type: "K7", id: "I73", question: "Saya dapat bertanggung jawab atas tindakan yang saya lakukan", value: 0 },
    { type: "K7", id: "I74", question: "Tidak nyaman dalam keramaian", value: 0 },
    { type: "K7", id: "I75", question: "Memiliki keinginan untuk hidup mandiri dan berpendirian kuat", value: 0 },
    { type: "K7", id: "I76", question: "Mengenali kelebihan dan kekurangan diri dengan baik", value: 0 },
    { type: "K7", id: "I77", question: "Saya lebih senang bermain sendiri", value: 0 },

    { type: "K8", id: "I78", question: "Sering membayangkan lawan bicara dengan orang yang kita kenal saat berargumen", value: 0 },
    { type: "K8", id: "I79", question: "Menggunakan barang yang ada disekitar untuk memperbaiki barang yang rusak", value: 0 },
    { type: "K8", id: "I80", question: "Lebih senang mengeompokkan informasi agar lebih mudah dipahami ", value: 0 },
    { type: "K8", id: "I81", question: "Memperhatikan orang-orang sekitar", value: 0 },
    { type: "K8", id: "I82", question: "Senang berkebun dan merawat tanaman", value: 0 },
    { type: "K8", id: "I83", question: "Senang jalan-jalan  di hutan (atau taman) dan melihat-lihat pohon, bunga serta keindahan alam", value: 0 },
    { type: "K8", id: "I84", question: "Mengoleksi beberapa barang , seperti perangko, lukisan, dll", value: 0 },
    { type: "K8", id: "I85", question: "Memiliki keinginan pindah ketempat yang sejuk agar dapat menikmati keindahan alamnya", value: 0 },
    { type: "K8", id: "I86", question: "Dalam mengingat suatu hal, akan dikelompokkan terlebih dahulu ", value: 0 },
    { type: "K8", id: "I87", question: "Senang saat mempelajari benda-benda disekitar", value: 0 },
    { type: "K8", id: "I88", question: "Saya suka memiliki hewan peliharaan", value: 0 },
  ]);

  const addIndicator = (type: string, id: string, question: string, value: 0 | 1) => {
    const newIndicator: Indicator = {
      type: type,
      id: id,
      question: question,
      value: value,
    };
    setIndicators((currIndicators) => {
      return currIndicators.concat(newIndicator);
    });
  };
  const findIndicator = (id: string) => {
    return indicators.filter((i) => {
      return i.id === id;
    })
  }
  const setValue = (i: Indicator, value: 0 | 1) => {
    const newInd: Indicator = {
      type: i.type,
      id: i.id,
      question: i.question,
      value: value
    }
    indicators.splice(indicators.indexOf(i), 1, newInd);
  };

  return (
    <IndicatorContext.Provider
      value={{
        indicators,
        addIndicator,
        setValue
      }}
    >
      {props.children}
    </IndicatorContext.Provider>
  );
};

export default IndicatorContextProvider;
