import { IonContent, IonHeader, IonIcon, IonItem, IonMenu, IonMenuToggle, IonPage, IonRouterOutlet, IonTitle, IonToolbar } from "@ionic/react";
import { bulbOutline, homeOutline, peopleSharp } from "ionicons/icons";
import { Redirect, Route } from 'react-router-dom';
import AboutUs from "./AboutUs";
import Home from "./Home";
import Test from "./Test";

const Menu: React.FC = () => {
    const paths = [
        { name: 'Home', link: '/app/home', icon: homeOutline },
        { name: 'Intelligence', link: '/app/intelligence', icon: bulbOutline },
        { name: 'About Us', link: '/app/aboutus', icon: peopleSharp },
    ]
    return (
        <IonPage>
            <IonMenu contentId="main">
                <IonHeader>
                    <IonToolbar color="secondary">
                        <IonTitle>Menu Content</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    {paths.map((item, idx) =>
                        <IonMenuToggle key={idx}>
                            <IonItem routerLink={item.link} routerDirection="forward">
                                {item.name}
                                <IonIcon icon={item.icon} slot="start"></IonIcon>
                            </IonItem>
                        </IonMenuToggle>
                    )}
                </IonContent>
            </IonMenu>
            <IonRouterOutlet id="main">
                <Redirect exact path="/app" to="/app/home"></Redirect>
                <Route exact path="/app/home" component={Home} />
                <Route exact path="/app/aboutus" component={AboutUs} />
                <Route exact path="/app/intelligence" component={Test} />
            </IonRouterOutlet>
        </IonPage>

    );
}

export default Menu;