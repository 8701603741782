import React from 'react';

export interface Indicator {
    type: string;
    id: string;
    question: string;
    value: 0 | 1;
}

export interface Context {
    indicators: Indicator[];
    addIndicator: (type: string, id: string, question: string, value: 0 | 1) => void;
    setValue: (i: Indicator, value: 0 | 1) => void;
}

const IndicatorContext = React.createContext<Context>({
    indicators: [],
    addIndicator: () => {},
    setValue: () => {}

});

export default IndicatorContext;