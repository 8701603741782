import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import About from "../Asset/aboutus.png";
import { useContext, useEffect } from 'react';
import IndicatorContext from '../data/indicator-context';
import findMe from "../Asset/findMe.png";

const AboutUs: React.FC = () => {
    const indicatorCtx = useContext(IndicatorContext);
    const listIndicators = indicatorCtx.indicators;
    useEffect(() => {
        console.log(listIndicators);
    }, [listIndicators])
    return (
        <IonPage id="main-content">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start" color='white'>
                        <IonMenuButton id="aboutus"></IonMenuButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton fill="clear" routerLink="/app/home">
                            <IonAvatar className="ion-padding">
                                <IonImg className="btnLogo" src={findMe} />
                            </IonAvatar>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>About Us</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <img src={About} alt="About" />
            </IonContent>
        </IonPage>
    );
};

export default AboutUs;
