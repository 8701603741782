import React, { useState } from "react";
import RecommendationContext, { Recommendation } from "./recommendation-context";

type contextProps = {
  children: React.ReactNode;
};
const RecommendationContextProvider = (props: contextProps) => {
  const [recommendations, setRecommendation] = useState<Recommendation[]>([
    { type: "R1", id: "I1", job: "Guru" },
    { type: "R1", id: "I2", job: "Sekretaris" },
    { type: "R1", id: "I3", job: "Pendongeng" },
    { type: "R1", id: "I4", job: "Orator" },
    { type: "R1", id: "I5", job: "Politisi" },
    { type: "R1", id: "I6", job: "Sastrawan" },
    { type: "R1", id: "I7", job: "Penulis" },
    { type: "R1", id: "I8", job: "Editor" },
    { type: "R1", id: "I9", job: "Wartawan" },
    { type: "R1", id: "I10", job: "Ilmuwan Sosial" },

    { type: "R2", id: "I11", job: "Insinyur" },
    { type: "R2", id: "I12", job: "Ahli Matematika" },
    { type: "R2", id: "I13", job: "Akuntan Pajak" },
    { type: "R2", id: "I14", job: "Ahli Statistik" },
    { type: "R2", id: "I15", job: "Ilmuwan" },
    { type: "R2", id: "I16", job: "Programmer Komputer" },
    { type: "R2", id: "I17", job: "Ahli Logika" },
    { type: "R2", id: "I18", job: "Filsuf" },

    { type: "R3", id: "I23", job: "Pemburu" },
    { type: "R3", id: "I24", job: "Pramuka" },
    { type: "R3", id: "I25", job: "Pemandu" },
    { type: "R3", id: "I26", job: "Dekorator Interior" },
    { type: "R3", id: "I27", job: "Arsitek" },
    { type: "R3", id: "I28", job: "Seniman" },
    { type: "R3", id: "I29", job: "Ahli Tata Kota" },

    { type: "R4", id: "I34", job: "Pilot" },
    { type: "R4", id: "I35", job: "Aktor" },
    { type: "R4", id: "I36", job: "Pemain Pantonim" },
    { type: "R4", id: "I37", job: "Atlet" },
    { type: "R4", id: "I38", job: "Penari" },
    { type: "R4", id: "I39", job: "Pengrajin" },
    { type: "R4", id: "I40", job: "Pematung" },
    { type: "R4", id: "I41", job: "Ahli Mekanik" },
    { type: "R4", id: "I42", job: "Dokter Bedah" },
    { type: "R4", id: "I43", job: "Tukang Kayu" },
    { type: "R4", id: "I44", job: "Montir" },

    { type: "R5", id: "I45", job: "Penikmat Musik" },
    { type: "R5", id: "I46", job: "Kritikus Musik" },
    { type: "R5", id: "I47", job: "Komposer" },
    { type: "R5", id: "I48", job: "Penyanyi" },
    { type: "R5", id: "I49", job: "Pemain Instrumen Musik" },

    { type: "R6", id: "I56", job: "Politisi" },
    { type: "R6", id: "I57", job: "Konselor" },
    { type: "R6", id: "I58", job: "Psikolog" },
    { type: "R6", id: "I59", job: "Event Organizer" },
    { type: "R6", id: "I60", job: "Pengusaha" },

    { type: "R7", id: "I67", job: "Psikoterapis" },
    { type: "R7", id: "I68", job: "Pemimpin Keagamaan" },

    { type: "R8", id: "I78", job: "Peneliti Alam" },
    { type: "R8", id: "I79", job: "Ahli Biologi" },
    { type: "R8", id: "I80", job: "Dokter Hewan" },
    { type: "R8", id: "I81", job: "Aktivis Lingkungan" },
    { type: "R8", id: "I82", job: "Pakar Ekologi" },
    { type: "R8", id: "I83", job: "Petani" },
  ]);

  const addRecommendation = (type: string, id: string, job: string) => {
    const newRecommendation: Recommendation = {
      type: type,
      id: id,
      job: job,
    };
    setRecommendation((currRecommendations) => {
      return currRecommendations.concat(newRecommendation);
    });
  };

  return (
    <RecommendationContext.Provider
      value={{
        recommendations,
        addRecommendation,
      }}
    >
      {props.children}
    </RecommendationContext.Provider>
  );
};

export default RecommendationContextProvider;
