import { IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import "./Home.css";
import background from "../Asset/background.png";
import find from "../Asset/find.png";
import findMe from "../Asset/findMe.png";

const Home: React.FC = () => {
  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start" color="white">
            <IonMenuButton id="main"></IonMenuButton>
          </IonButtons>
          <IonTitle>Home</IonTitle>
          <IonButtons slot="end">
            <IonButton fill="clear" routerLink="/app/home">
              <IonAvatar className="ion-padding">
                <IonImg className="btnLogo" src={findMe} />
              </IonAvatar>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <img className="bg" src={background} alt="" />
        <div className="container ion-margin-top">
          <div className="box">
            <div className="inner">
              <span>Welcome To <b>FindMe</b></span>
            </div>
            <div className="inner">
              <span>Welcome To <b>FindMe</b></span>
            </div>
          </div>
        </div>
        <img className="logo" src={find} alt="" />
        <IonButton color="warning" className="btn" routerLink="/app/intelligence">
          <IonLabel color="light">Go To Test</IonLabel>
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Home;
